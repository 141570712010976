import { Datepicker } from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/js/i18n/locales/ru';

Object.assign(Datepicker.locales, ru);

/*
  Vanillajs-datepicker
  https://mymth.github.io/vanillajs-datepicker/#/
*/

/**
  * @param  {Element} datepickerSelectors - HTML container element, default document
*/
export default function datepicker(datepickerSelectors) {
  let datepickers;

  if (datepickerSelectors && typeof datepickerSelectors === 'object' && datepickerSelectors.length > 0) {
    datepickers = datepickerSelectors;
  } else {
    datepickers = document.querySelectorAll('[data-datepicker]');
  }

  if (!datepickers) return;

  datepickers.forEach((item) => {
    const datepickerEl = item;

    const options = {
      language: 'ru',
      prevArrow: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>',
      nextArrow: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>',
      autohide: true,
      format: 'dd.mm.yyyy',
    };

    const datepickerPlugin = new Datepicker(datepickerEl, options);

    datepickerEl.datepicker = datepickerPlugin;

    datepickerEl.addEventListener('changeDate', () => {
      const change = new Event('change');

      datepickerEl.dispatchEvent(change);
      datepickerEl.classList.remove('is-error');
      datepickerEl.closest('.js-field-box').classList.remove('is-error');
    });

    datepickerEl.addEventListener('clearInput', () => {
      datepickerPlugin.setDate(null);
      datepickerPlugin.update();
      datepickerPlugin.refresh();
    });
  });
}
