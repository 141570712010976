/*
      -------------
    |   DROPDOWN   |
      -------------

    * Basic Attributes:
        * data-dropdown - dropdown menu wrapper
        * data-dropdown-button - popup open/close button
        * data-dropdown-button-text - button text for opening/closing the popup window
        * data-dropdown-popup - pop-up window
        * data-dropdown-item - select list element
        * data-dropdown-input - Input when selected from dropdown list
*/

/**
  * Dropdown menu
  * @param  {Element} dropdownContainer - HTML container element, default document
*/
export default function dropdown(dropdownContainer) {
  let dropdowns;

  if (dropdownContainer) {
    if (dropdownContainer instanceof Node) {
      dropdowns = dropdownContainer.querySelectorAll('[data-dropdown]');
    }
  } else {
    dropdowns = document.querySelectorAll('[data-dropdown]');
  }

  if (!dropdowns) return;

  /**
    * Close active dropdown menu
    * @param  {Element} target - HTML dropdown menu element
  */
  const closeDropdownActive = (target) => {
    const dropdownActive = document.querySelector('[data-dropdown].is-active');

    if (dropdownActive && (!target || (dropdownActive && target !== dropdownActive && !target.closest('[data-dropdown]')))) {
      dropdownActive.classList.remove('is-active');
    }
  };

  /**
    * Dropdown offset
  */
  const calcMarginLeft = () => {
    const dropdownActive = document.querySelector('[data-dropdown].is-active');

    if (dropdownActive) {
      const dropdownActiveContent = dropdownActive.querySelector('[data-dropdown-popup]');

      if (dropdownActiveContent) {
        const clientRect = dropdownActiveContent.getBoundingClientRect();

        dropdownActiveContent.style.removeProperty('margin-left');

        if ((clientRect.x + clientRect.width + 10) > window.innerWidth) {
          dropdownActiveContent.style.marginLeft = `${(clientRect.x + clientRect.width + 10 - window.innerWidth) * (-1)}px`;
        } else {
          dropdownActiveContent.style.removeProperty('margin-left');
        }
      }
    }
  };

  /**
    * Dropdown menu initialization
    * @param  {Element} dropdownItem - HTML element dropdown menu
  */
  const dropdownInit = (dropdownItem) => {
    const dropdownSelectItems = dropdownItem.querySelectorAll('[data-dropdown-item]');
    const dropdownInput = dropdownItem.querySelector('[data-dropdown-input]');
    const buttonTextEl = dropdownItem.querySelector('[data-dropdown-button-text]');
    const dropdownLang = dropdownItem.querySelector('[data-dropdown-lang]');

    dropdownItem.setAttribute('data-dropdown-init', '');

    dropdownSelectItems.forEach((item) => {
      item.addEventListener('click', () => {
        const value = item.getAttribute('data-value');
        const valueLang = item.getAttribute('data-value-lang');

        for (let i = 0; i < dropdownSelectItems.length; i += 1) {
          dropdownSelectItems[i].classList.remove('is-selected');
        }

        item.classList.add('is-selected');
        dropdownItem.classList.remove('is-active');
        dropdownItem.classList.remove('is-placeholder');

        if (buttonTextEl) {
          buttonTextEl.textContent = value || '';
        }

        if (dropdownInput) {
          dropdownInput.value = value;
          dropdownInput.classList.remove('is-error');
        }

        if (dropdownLang && valueLang && valueLang.length) {
          const langSrc = dropdownLang.getAttribute('data-lang-src');

          if (langSrc && langSrc.length) {
            dropdownLang.setAttribute('src', `${langSrc + valueLang}.svg`);
          }
        }
      });
    });

    if (dropdownInput) {
      dropdownInput.addEventListener('changeDate', (event) => {
        if (buttonTextEl) {
          buttonTextEl.textContent = event.target.value || '';
        }
        closeDropdownActive();
      });
    }
  };

  /**
    * Opening dropdown menu
    * @param  {Element} dropdownItem - HTML element dropdown menu
  */
  const dropdownOpen = (target) => {
    let dropdownCurrent;
    let dropdownButton;
    let dropdownInput;

    if (target.closest('[data-dropdown-button]') || target.hasAttribute('data-dropdown-button')) {
      dropdownCurrent = target.closest('[data-dropdown]');
    }

    if (dropdownCurrent) {
      dropdownButton = dropdownCurrent.querySelector('[data-dropdown-button]');
      dropdownInput = dropdownCurrent.querySelector('[data-dropdown-input]');

      if (dropdownButton) {
        const dropdownActive = document.querySelector('[data-dropdown].is-active');

        if (dropdownActive && dropdownActive !== dropdown) {
          dropdownActive.classList.remove('is-active');
          dropdownCurrent.classList.add('is-active');
        } else {
          dropdownCurrent.classList.toggle('is-active');
        }

        if (dropdownCurrent.classList.contains('is-active')) {
          if (dropdownInput && dropdownInput.hasAttribute('data-datepicker')) {
            dropdownInput.focus();
          }
        }

        calcMarginLeft();
      }
    }
  };

  dropdowns.forEach((dropdownItem) => {
    if (!dropdownItem.hasAttribute('data-dropdown-init')) {
      dropdownInit(dropdownItem);
    }
  });

  document.addEventListener('click', (event) => {
    closeDropdownActive(event.target);
    dropdownOpen(event.target);
  });

  document.addEventListener('keydown', (e) => {
    if (
      document.activeElement.closest('[data-dropdown].is-active')
      && !document.activeElement.closest('.datepicker') && !document.activeElement.classList.contains('datepicker')
      && (e.key === 'Escape' || e.code === 'Escape')) {
      closeDropdownActive();
    }
  });

  window.addEventListener('resize', () => {
    calcMarginLeft();
  });
}
