import { getCookie, setCookie } from './cookie/cookie';

export default function controlWcagPanel() {
  document.addEventListener('wcag:action', (event) => {
    const config = event.wcagState;
    if (config && config instanceof Object) {
      const html = document.documentElement;

      config.forEach((key) => {
        html.setAttribute(`data-${key.name}`, key.value);
      });
    }
  });

  const WCAGPanel = function WCAGPanel(panel) {
    this.controlPanel = panel;
    this.state = [];

    this.event = document.createEvent('Event');
    this.event.initEvent('wcag:action', true, false);

    this.init();
  };

  WCAGPanel.prototype.init = function initPanel() {
    this.restoreConfigFromStorage();

    this.handleClick();

    this.handleDropdownKeyup();

    this.handleChange();

    this.controlPanel.addEventListener('submit', (event) => {
      event.preventDefault();
    });
  };

  WCAGPanel.prototype.getPanelState = function getPanelState() {
    const state = [];

    const checkedElements = this.controlPanel.querySelectorAll('input:checked');

    [].forEach.call(checkedElements, (element) => {
      state.push({
        name: element.name,
        value: element.value,
      });
    });

    return state;
  };

  WCAGPanel.prototype.restorePanelState = function restorePanelState(state) {
    const self = this;

    if (state instanceof Array) {
      state.forEach((elementState) => {
        const element = self.controlPanel.querySelector(`[name="${elementState.name}"][value="${elementState.value}"]`);

        if (element) {
          element.checked = true;
        }
      });
    }
  };

  WCAGPanel.prototype.triggerEvent = function triggerEvent() {
    this.state = this.getPanelState();
    this.event.wcagState = this.state;
    this.storeConfigToStorage(this.state);
    this.controlPanel.dispatchEvent(this.event);
  };

  WCAGPanel.prototype.storeConfigToStorage = function storeConfigToStorage(config) {
    setCookie('wcagstate', JSON.stringify(config));
  };

  WCAGPanel.prototype.restoreConfigFromStorage = function restoreConfigFromStorage() {
    const wcagstate = getCookie('wcagstate');
    if (!wcagstate) return;

    try {
      const config = JSON.parse(wcagstate);
      this.restorePanelState(config);
      this.triggerEvent();
    } catch (e) {
      window.console.error('Cannot get state from storage');
    }
  };

  WCAGPanel.prototype.handleClick = function handleClick() {
    const self = this;

    this.controlPanel.addEventListener('click', (event) => {
      if (event.target.hasAttribute('data-wcag-panel')) {
        const targetRole = event.target.getAttribute('data-wcag-panel');

        if (targetRole === 'reset-config') {
          self.controlPanel.reset();
          self.triggerEvent();
        }
      }
    });
  };

  WCAGPanel.prototype.handleChange = function handleChange() {
    const self = this;
    this.controlPanel.addEventListener('change', () => {
      self.triggerEvent();
    });
  };

  WCAGPanel.prototype.handleDropdownKeyup = function handleDropdownKeyup() {
    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        this.closeDropdown();
        this.controlPanel.reset();
        this.triggerEvent();
      }
    });
  };

  WCAGPanel.prototype.closeDropdown = function closeDropdown() {
    this.controlPanel.classList.remove('visible-wcag-panel');
  };

  const panel = document.getElementById('wcag-panel');
  if (panel) {
    new WCAGPanel(panel);
  }
}
