import Choices from 'choices.js';

/*
  Choices.js
  https://github.com/Choices-js/Choices
*/

export default function choices() {
  const selects = document.querySelectorAll('[data-choice]');

  if (!selects) return;

  const changedSelect = (value, choicesEl, inputEl) => {
    if (choicesEl) {
      if (value && value.length) {
        choicesEl.classList.add('is-changed');
      } else {
        choicesEl.classList.remove('is-changed');
      }

      if (inputEl.classList.contains('is-error')) {
        const errorMessage = choicesEl.querySelector('.is-label-error');

        choicesEl.classList.add('is-error');

        if (errorMessage) {
          choicesEl.after(errorMessage);
        }
      } else {
        choicesEl.classList.remove('is-error');
      }
    }
  };

  selects.forEach((item) => {
    const choicesPlugin = new Choices(item, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      removeItemButton: true,
    });

    changedSelect(item.value, item.closest('.choices'), item);

    item.addEventListener('change', (event) => {
      const choicesEl = event.target.closest('.choices');

      changedSelect(event.detail.value, choicesEl, event.target);
    });

    item.addEventListener('clearChoices', () => {
      choicesPlugin.setChoiceByValue('');
    });
  });
}
