import Swiper, {
  Keyboard,
  Navigation,
  Pagination,
  Parallax,
  Autoplay,
  FreeMode,
  Mousewheel,
} from 'swiper';

/*
  Swiper
  https://swiperjs.com/swiper-api
*/

const homeSlider = document.querySelector('.js-home-slider');

if (homeSlider) {
  const homeSliderFraction = homeSlider.querySelector('.js-home-slider-fraction');

  new Swiper(homeSlider, {
    modules: [Keyboard, Navigation, Pagination, Parallax, Autoplay],
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    parallax: true,
    speed: 800,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      prevEl: '.js-home-slider-prev',
      nextEl: '.js-home-slider-next',
    },
    pagination: {
      el: '.js-home-slider-pagination',
      clickable: true,
    },
    breakpoints: {
      576: {
        spaceBetween: 0,
      },
    },
    on: {
      init: (event) => {
        let slides = event.slides.length;

        if (slides <= 9) {
          slides = `0${slides}`;
        }

        if (homeSliderFraction) {
          homeSliderFraction.innerHTML = `<span>01 /</span> ${slides}`;
        }
      },
      slideChangeTransitionEnd: (event) => {
        let slides = event.slides.length;
        let currentSlide = event.realIndex + 1;

        if (slides <= 9) {
          slides = `0${slides}`;
        }

        if (currentSlide <= 9) {
          currentSlide = `0${currentSlide}`;
        }

        if (homeSliderFraction) {
          homeSliderFraction.innerHTML = `<span>${currentSlide} /</span> ${slides}`;
        }
      },
    },
  });
}

new Swiper('.js-tours-slider', {
  modules: [Keyboard, Navigation, Parallax],
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  speed: 800,
  parallax: true,
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  navigation: {
    prevEl: '.js-tours-slider-prev',
    nextEl: '.js-tours-slider-next',
  },
  breakpoints: {
    576: {
      spaceBetween: 0,
      slidesPerView: 1,
    },
  },
});

new Swiper('.js-last-news-slider', {
  modules: [Keyboard, FreeMode, Mousewheel],
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  mousewheel: true,
  freeMode: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: false,
  breakpoints: {
    768: {
      spaceBetween: 20,
    },
  },
});

new Swiper('.js-vacancies-slider', {
  modules: [Keyboard, FreeMode, Mousewheel],
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  mousewheel: true,
  freeMode: true,
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: false,
  breakpoints: {
    768: {
      spaceBetween: 20,
    },
  },
});

new Swiper('.js-photos-slider', {
  modules: [Keyboard, Navigation],
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  navigation: {
    prevEl: '.js-photos-slider-prev',
    nextEl: '.js-photos-slider-next',
  },
});
