import JustValidate from 'just-validate';
// import JustValidatePluginDate from 'just-validate-plugin-date';

import IMask from 'imask';

export default function validation() {
  const maskDates = document.querySelectorAll('.js-mask-date');

  maskDates.forEach((date) => {
    IMask(date, {
      mask: '00.00.0000',
    });
  });

  const options = {
    errorFieldCssClass: 'is-error',
    errorLabelStyle: false,
    errorLabelCssClass: 'is-label-error',
  };

  const defaultFieldOptions = [
    {
      rule: 'required',
      errorMessage: 'Обязательное поле',
    },
  ];

  const emailFieldOptions = [
    {
      rule: 'required',
      errorMessage: 'Обязательное поле',
    },
    {
      rule: 'email',
      errorMessage: 'Укажите корректный email',
    },
  ];

  const phoneFieldOptions = [
    {
      rule: 'required',
      errorMessage: 'Обязательное поле',
    },
    {
      rule: 'minLength',
      value: 18,
      errorMessage: 'Неверный формат телефона',
    },
  ];

  const dateFieldOptions = [
    {
      rule: 'required',
      errorMessage: 'Обязательное поле',
    },
    // {
    //   plugin: JustValidatePluginDate(() => ({
    //     format: 'dd.mm.yyyy',
    //   })),
    //   errorMessage: 'Дата должна быть в формате дд.мм.гггг (например, 31.01.2023)',
    // },
  ];

  const isErrorFieldBox = (fields) => {
    Object.keys(fields).forEach((key) => {
      const item = fields[key];
      const fieldBox = item.elem.closest('.js-field-box');
      const choices = item.elem.closest('.choices');

      if (fieldBox) {
        if (!item.isValid && item.isValid !== undefined && !fieldBox.querySelector('[data-datepicker]')) {
          fieldBox.classList.add('is-error');
        } else {
          fieldBox.classList.remove('is-error');
        }

        if (fieldBox.querySelector('[data-datepicker]') && item.elem.value !== '') {
          fieldBox.classList.remove('is-error');
          item.elem.classList.remove('is-error');
        }
      }

      if (choices) {
        const errorMessage = choices.querySelector('.is-label-error');

        if (!item.isValid && item.isValid !== undefined) {
          choices.classList.add('is-error');

          if (errorMessage) {
            choices.after(errorMessage);
          }
        } else {
          choices.classList.remove('is-error');
        }
      }
    });
  };

  // Валидация форм
  const forms = document.querySelectorAll('[data-js-form]');
  const successMessage = document.querySelector('#success_message');
  const hideSuccess = () => {
    if (successMessage) {
      successMessage.classList.add('is-hidden');
    }
  };
  // const showSuccess = () => {
  //   if (successMessage) {
  //     successMessage.classList.add('is-active');
  //   }
  // };
  // const sendData = (form, data) => {
  //   const XHR = new XMLHttpRequest();
  //
  //   let urlEncodedData = '';
  //   const urlEncodedDataPairs = [];
  //
  //   for (let i = 0; i < data.length; i += 1) {
  //     const value = data[i];
  //
  //     if (value) {
  //       urlEncodedDataPairs
  //       .push(`${encodeURIComponent(value)}=${encodeURIComponent(data[value])}`);
  //     }
  //   }
  //
  //   urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');
  //
  //   XHR.addEventListener('load', () => {
  //     showSuccess();
  //
  //     setTimeout(() => {
  //       hideSuccess();
  //     }, 4000);
  //   });
  //
  //   XHR.addEventListener('error', () => {});
  //
  //   XHR.open(form.method ? form.method : 'POST', form.action ? form.action : '');
  //   XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //   XHR.send(urlEncodedData);
  // };
  forms.forEach((form) => {
    const validate = new JustValidate(form, options);
    const formFields = form.querySelectorAll('[data-js-form-field]');

    const allInputs = form.getElementsByTagName('input');
    for (let index = 0; index < allInputs.length; index += 1) {
      const input = allInputs[index];

      input.addEventListener('focus', () => {
        hideSuccess();
      });
    }

    const allSelects = form.getElementsByTagName('select');
    for (let index = 0; index < allSelects.length; index += 1) {
      const select = allSelects[index];

      select.addEventListener('change', () => {
        hideSuccess();
      });
    }

    const allTextareas = form.getElementsByTagName('textarea');
    for (let index = 0; index < allTextareas.length; index += 1) {
      const textarea = allTextareas[index];

      textarea.addEventListener('focus', () => {
        hideSuccess();
      });
    }

    // Выбор даты загранпаспорта
    const selectPassport = form.querySelector('#select_international_passport');
    const datePassport = form.querySelector('#date_international_passport');
    const datePassportFrom = form.querySelector('#date_international_passport_from');
    const datePassportTo = form.querySelector('#date_international_passport_to');
    const datePassportError = form.querySelector('#date_international_error_msg');

    const checkRangeDates = () => {
      const from = datePassportFrom.datepicker.getDate();
      const to = datePassportTo.datepicker.getDate();
      let isReturn = true;

      if (from && to) {
        if (datePassportError) {
          if (to.getTime() <= from.getTime()) {
            datePassportError.style.display = 'block';

            setTimeout(() => {
              datePassportFrom.classList.add('is-error', 'is-error-no-message');
              datePassportTo.classList.add('is-error', 'is-error-no-message');
            }, 10);

            isReturn = false;
          } else {
            datePassportError.style.removeProperty('display');
            datePassportFrom.classList.remove('is-error', 'is-error-no-message');
            datePassportTo.classList.remove('is-error', 'is-error-no-message');

            isReturn = true;
          }

          return isReturn;
        }
      } else {
        datePassportError.style.removeProperty('display');
      }
      return isReturn;
    };

    if (formFields.length) {
      for (let index = 0; index < formFields.length; index += 1) {
        const field = formFields[index];

        const fieldName = field.getAttribute('name');
        let fieldOptions = defaultFieldOptions;

        if (fieldName === 'email') {
          fieldOptions = emailFieldOptions;
        }

        if (fieldName === 'phone') {
          fieldOptions = phoneFieldOptions;

          const maskPhone = IMask(field, {
            mask: '+{7} (000) 000-00-00',
          });

          field.addEventListener('clearInput', () => {
            maskPhone.value = '';
          });
        }

        if (field.classList.contains('js-mask-date')) {
          fieldOptions = dateFieldOptions;

          field.addEventListener('change', () => {
            // validate.refresh();
          });
        }

        validate.addField(`[name="${fieldName}"]`, fieldOptions);

        field.addEventListener('change', () => {
          hideSuccess();
        });

        field.addEventListener('input', () => {
          hideSuccess();
        });
      }
    }

    if (selectPassport) {
      const clearFieldPassport = (field) => {
        const fieldCurrent = field;

        fieldCurrent.classList.remove('is-validate');
        fieldCurrent.value = '';
        fieldCurrent.datepicker.update();
        fieldCurrent.datepicker.refresh();
        fieldCurrent.closest('.js-field-box').classList.remove('is-filed');
      };

      selectPassport.addEventListener('change', (event) => {
        if (datePassport) {
          if (event.target.value === '1') {
            datePassport.classList.remove('is-hidden');

            if (datePassportFrom) {
              validate.addField(`[name="${datePassportFrom.getAttribute('name')}"]`, [
                {
                  rule: 'required',
                  errorMessage: 'Обязательное поле',
                },
                {
                  validator: () => checkRangeDates(),
                },
              ]);

              datePassportFrom.classList.add('is-validate');
            }

            if (datePassportTo) {
              validate.addField(`[name="${datePassportTo.getAttribute('name')}"]`, [
                {
                  rule: 'required',
                  errorMessage: 'Обязательное поле',
                },
                {
                  validator: () => checkRangeDates(),
                },
              ]);

              datePassportTo.classList.add('is-validate');
            }
          } else {
            datePassport.classList.add('is-hidden');

            if (datePassportFrom && datePassportFrom.classList.contains('is-validate')) {
              validate.removeField(`[name="${datePassportFrom.getAttribute('name')}"]`);

              clearFieldPassport(datePassportFrom);
            }

            if (datePassportTo && datePassportTo.classList.contains('is-validate')) {
              validate.removeField(`[name="${datePassportTo.getAttribute('name')}"]`);

              clearFieldPassport(datePassportTo);
            }
          }
        }
      });

      datePassportFrom.addEventListener('change', () => {
        checkRangeDates();
      });

      datePassportTo.addEventListener('change', () => {
        checkRangeDates();
      });
    }

    validate.onValidate((event) => {
      isErrorFieldBox(event.fields);
    });

    validate.onSuccess((event) => {
      /*
      const data = new FormData(event.target);

      const fieldsBox = event.target.querySelectorAll('.js-field-box');
      const selects = event.target.querySelectorAll('select:not([name="vacancy"])');
      const clearChoices = new Event('clearChoices');

      const attaches = event.target.querySelectorAll('[data-attach-file]');
      const attachInput = event.target.querySelector('[data-attach-input]');

      attaches.forEach((attachItem) => {
        const fileId = attachItem.getAttribute('data-id');
        const fileName = attachItem.getAttribute('data-name');

        removeFileFromFileList(attachInput, fileId, fileName);
        attachItem.remove();
      });

      event.target.reset();

      for (let i = 0; i < fieldsBox.length; i += 1) {
        const field = fieldsBox[i];

        field.classList.remove('is-filed');
      }

      for (let i = 0; i < selects.length; i += 1) {
        const select = selects[i];
        select.dispatchEvent(clearChoices);

        if (select.closest('.is-changed')) {
          select.closest('.is-changed').classList.remove('is-changed');
        }
      }

      sendData(event.target, data);
      */
      event.target.submit();
    });
  });
}
