/*
     ---------------
  |   FIX HEADER   |
    ---------------
*/

export default function headerFixed() {
  const header = document.querySelector('.js-header');

  if (!header) return;

  const fixedClass = 'is-fixed';
  let scrollTop = 0;
  let headerHeight;
  let positionTop;

  /**
    * Header fixing
  */
  const fixed = () => {
    scrollTop = window.pageYOffset;
    headerHeight = header.offsetHeight;
    positionTop = header.getBoundingClientRect().top + scrollTop + headerHeight;

    if (scrollTop > positionTop * 1.5) {
      header.classList.add(fixedClass);
      header.style.height = `${headerHeight}px`;
    } else {
      header.classList.remove(fixedClass);
      header.style.removeProperty('height');
    }
  };

  fixed();

  window.addEventListener('scroll', fixed);
}
