/* Common libraries ************************ */
import './js/vendor';
import LazyLoad from 'vanilla-lazyload';

/* Common scripts ******************* */
import './js/scripts/setHeight';

/* Common components ******************* */
import choices from './js/components/choices';
import datepicker from './js/components/datepicker';
import dropdown from './js/components/dropdown';
import tabs from './js/components/tabs';
import gallery from './js/components/gallery';
import modal from './js/components/modal';
import './js/components/swiper';

/* Form components ***************** */
import fieldBox from './js/form/fieldBox';
import attach from './js/form/attach';

/* Header scripts ******************* */
import headerMenu from './js/header/headerMenu';
import headerFixed from './js/header/headerFixed';

/* Validation ************************ */
import validation from './js/validation';

/* Version for the visually impaired ******************* */
import controlWcagPanel from './js/wcag-panel';
import openWcagPanel from './js/open-wcag-panel';

openWcagPanel();
controlWcagPanel();

choices();
datepicker();
dropdown();
tabs();
gallery();
modal.init();

fieldBox();
attach();

headerFixed();
headerMenu(1024);

validation();

// Lazy Load
new LazyLoad();
