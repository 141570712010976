/*
    --------------
  |  HEADER  MENU  |
   ---------------
*/

/**
  * Header Menu
  * @param  {number} delay - menu opening time (also needs to be changed in CSS)
  * @param  {number} widthMobile - the width of the browser at which the nested menu
  transitions to the hidden mobile
*/
export default function headerMenu(widthMobile = 991, delay = 300) {
  /**
    * @param  {number} widthMobile - the width of the browser at which the nested menu
    transitions to the hidden mobile
    * @param  {number} delay - menu opening time (also needs to be changed in CSS)
  */

  const menu = document.querySelector('.js-menu');

  if (!menu) return;

  const menuBurger = menu.querySelector('.js-menu-burger');
  const menuPopup = menu.querySelector('.js-menu-popup');
  const menubackdrop = menu.querySelector('.js-menu-backdrop');
  const menuClose = menu.querySelector('.js-menu-close');
  const menuDropdownItems = menu.querySelectorAll('.js-menu-dropdown');
  let isMenu = true;

  /**
    * Adding the body class of the opened menu and assigning the scrollbar
    width padding variable
  */
  const lockBody = () => {
    const lockPaddingValue = `${window.innerWidth - document.body.offsetWidth}px`;
    document.documentElement.style.setProperty('--lock-padding-right', lockPaddingValue);
    document.body.classList.add('is-menu-active');
  };

  /**
    * Removing the open menu's body class and removing the scrollbar
    width padding variable
  */
  const unlockBody = () => {
    document.documentElement.style.removeProperty('--lock-padding-right');
    document.body.classList.remove('is-menu-active');
  };

  /**
    * Menu opening
  */
  const openMenu = () => {
    if (isMenu) {
      isMenu = false;

      if (menuBurger) {
        menuBurger.classList.add('is-active');
      }

      if (menubackdrop) {
        menubackdrop.classList.add('is-active');
      }

      if (menuPopup) {
        menuPopup.classList.add('is-visible');

        setTimeout(() => {
          menuPopup.classList.add('is-active');
        }, 1);
      }

      lockBody();

      setTimeout(() => {
        isMenu = true;
      }, delay);
    }
  };

  /**
    * Closing the menu
  */
  const closeMenu = () => {
    if (isMenu) {
      isMenu = false;

      if (menuBurger) {
        menuBurger.classList.remove('is-active');
      }

      if (menubackdrop) {
        menubackdrop.classList.remove('is-active');
      }

      if (menuPopup) {
        menuPopup.classList.remove('is-active');

        setTimeout(() => {
          menuPopup.classList.remove('is-visible');
        }, delay);
      }

      unlockBody();

      setTimeout(() => {
        isMenu = true;
      }, delay);
    }
  };

  menuDropdownItems.forEach((item) => {
    const menuLink = item.querySelector('.js-menu-link');

    const openSubmenu = () => {
      if (window.innerWidth > widthMobile) {
        item.classList.add('is-active');
      }
    };

    const closeSubmenu = () => {
      if (window.innerWidth > widthMobile) {
        item.classList.remove('is-active');
      }
    };

    menuLink.addEventListener('focus', () => {
      if (item.classList.contains('js-menu-dropdown')) {
        openSubmenu();
      } else {
        closeSubmenu();
      }
    });

    item.addEventListener('mouseenter', () => {
      openSubmenu();
    });

    item.addEventListener('mouseleave', () => {
      if (window.innerWidth > widthMobile) {
        closeSubmenu();
      }
    });
  });

  if (menuBurger) {
    menuBurger.addEventListener('click', () => {
      if (menuBurger.classList.contains('is-active')) {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }

  if (menuClose) {
    menuClose.addEventListener('click', () => {
      closeMenu();
    });
  }

  if (menubackdrop) {
    menubackdrop.addEventListener('click', () => {
      closeMenu();
    });
  }

  document.addEventListener('keydown', (e) => {
    if ((e.key === 'Escape' || e.code === 'Escape')
      && (document.activeElement.classList.contains('is-menu-active')
        || document.activeElement.closest('.js-menu')
        || document.activeElement === menuBurger)) {
      closeMenu();
    }
  });
}
