/*
    --------------
  |   ПОЛЯ ФОРМЫ   |
    --------------

  * Базовые атрибуты:
    * data-field - обёртка для поля
      ** data-field-password - атрибут для возможности показать пароль
    * data-field-input - элемент input
    * data-field-icon - иконка поля (необходима для отображения/скрытия пароля)
*/

export default function fieldBox(containerEl) {
  /*
    @param  {Element} containerEl - HTML элемент контейнера, по умолчанию document
  */

  let fiedlsItems;

  if (containerEl) {
    if (containerEl instanceof Node) {
      fiedlsItems = containerEl.querySelectorAll('.js-field-box');
    }
  } else {
    fiedlsItems = document.querySelectorAll('.js-field-box');
  }

  if (!fiedlsItems) return;

  const evtClear = new Event('clearInput');

  fiedlsItems.forEach((item) => {
    const changeInput = (inputEl) => {
      const val = inputEl.value.trim();

      if (val !== '') {
        item.classList.add('is-filed');
      } else {
        item.classList.remove('is-filed');
      }

      if (inputEl.classList.contains('is-error')) {
        item.classList.add('is-error');
      } else {
        item.classList.remove('is-error');
      }
    };

    const fieldInit = () => {
      const inputEl = item.querySelector('.js-field-box-input');
      const btnClear = item.querySelector('.js-field-box-clear');

      item.setAttribute('data-field-box-init', '');

      if (inputEl) {
        changeInput(inputEl);

        inputEl.onfocus = () => {
          item.classList.add('is-focused');
        };

        inputEl.onblur = () => {
          item.classList.remove('is-focused');

          if (inputEl.hasAttribute('data-datepicker')) {
            if (!inputEl.datepicker.getDate()) {
              item.classList.remove('is-filed');
            }
          }

          if (inputEl.value.trim() === '') {
            inputEl.value = '';
          }
        };

        inputEl.addEventListener('input', () => {
          changeInput(inputEl);
        });

        inputEl.addEventListener('change', () => {
          changeInput(inputEl);
        });

        if (btnClear) {
          btnClear.addEventListener('click', () => {
            inputEl.value = '';

            changeInput(inputEl);
            inputEl.dispatchEvent(evtClear);

            if (inputEl.hasAttribute('data-datepicker')) {
              inputEl.datepicker.setDate('');
            }
          });
        }
      }
    };

    if (!item.hasAttribute('data-field-box-init')) {
      fieldInit();
    }
  });
}
