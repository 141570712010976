/**
 * Возвращает cookie с именем name, если есть, если нет, то undefined
 * @param name
 * @returns {any}
 */
const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Запись в document.cookie
 * @param name — название cookie
 * @param value — значение cookie
 * @param options — объект с дополнительными свойствами
 * для установки cookie (см. спецификацию document.cookie)
 */
const setCookie = (name, value, options) => {
  const currentOptions = options || {};
  let { expires } = currentOptions;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = d;
  }
  if (expires && expires.toUTCString) {
    currentOptions.expires = expires.toUTCString();
  }

  const currentValue = encodeURIComponent(value);
  let updatedCookie = `${name}=${currentValue}`;

  for (const propName in currentOptions) {
    if (Object.hasOwn(currentOptions, propName)) {
      updatedCookie += `; ${propName}`;
      const propValue = currentOptions[propName];
      if (propValue !== true) {
        updatedCookie += `=${propValue}`;
      }
    }
  }

  document.cookie = `${updatedCookie}; path=/;}`;
};

export { getCookie, setCookie };
