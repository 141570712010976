import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';

export default function gallery() {
  const galleryElements = document.querySelectorAll('[data-photos-gallery]');

  if (!galleryElements.length) return;

  for (let index = 0; index < galleryElements.length; index += 1) {
    const galleryEl = galleryElements[index];

    lightGallery(galleryEl, {
      plugins: [lgZoom],
      speed: 500,
      download: false,
      counter: false,
      actualSize: false,
      mobileSettings: {
        counter: false,
        showCloseIcon: true,
        download: false,
        actualSize: false,
      },
    });
  }
}
