export default function openWcagPanel() {
  const wcagPanel = document.getElementById('wcag-panel');
  const wcagPanelBtn = document.getElementById('wcag-panel-btn');

  wcagPanelBtn.addEventListener('click', () => {
    if (wcagPanel.classList.contains('visible-wcag-panel')) {
      wcagPanel.classList.remove('visible-wcag-panel');
    } else {
      wcagPanel.classList.add('visible-wcag-panel');
    }
  });
}
